// /* eslint-disable */
import React, { useState, useEffect } from 'react';
// import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Header from '../../../../common/components/Header';
import Loader from '../../../../common/components/Loader';
import Mixpanel from '../../../../utils/mixpanel';
import { terminalsPath } from '../../../../app/components/Card/data';
import InputBoxOncoTagging from '../../container/InputBoxOncoTagging';
import SelectService from '../../container/SelectService';

function OncoTaggingInputPage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const service = searchParams.get('service');
  const [disableButton, setDisableButton] = useState(true);
  // const dispatch = useDispatch();

  const [formData, setFormData] = useState();
  const [inputs, setInputs] = useState({
    card: '',
    inputText: '',
  });
  const handleClick = () => {
    Mixpanel.track('Ontology Tagging Input Submitted', {
      action: 'Ontology Tagging Input submitted',
      service: inputs.card,
      inputText: inputs.inputText,
      module: 'Utilities',
    });
    navigate(`${terminalsPath.metad3_utilities_output}?inputText=${inputs.inputText}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.log('input', inputs);
    let check = false;
    if (!formData) {
      check = inputs.inputText === '' || inputs.inputText === undefined;
    } else {
      check = !(formData !== null && formData && 'get' in formData);
    }
    check = check || !inputs.card;
    setDisableButton(check);
  }, [inputs, formData]);

  const getInputs = (input) => {
    setInputs((prev) => ({
      ...prev,
      ...input,
    }));
  };

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home adc-header" logo="utilities-logo" width={500} modulePath={terminalsPath.metad3_utilities} />
      <Loader loading={false}>
        <div className="ct-home-page">
          <div className="main-heading">
            <div className="main-title">Utilities heading</div>
            <div className="main-description">Utilities subheading.</div>
          </div>
          <div>
            <SelectService inputHandler={getInputs} service={service} resetInputs={() => setInputs({})} />
            <InputBoxOncoTagging inputHandler={getInputs} serviceName={inputs.card} setFormData={setFormData} isDisabled={inputs.card === ''} />
            <div className="ct-home-page-footer">
              <button onClick={handleClick} disabled={disableButton} className="generate-in-btn" type="button">
                Generate Prot ID
              </button>
            </div>
          </div>
        </div>
        {/* <SaveProjectModal name={inputs.protein_name} service={inputs.card} open={isSubmitted} setOpen={setIsSubmitted} requestBody={requestBody} formData={formData} /> */}
      </Loader>
    </div>
  );
}

export default OncoTaggingInputPage;
