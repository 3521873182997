import React from 'react';
// import PropTypes from 'prop-types';
import HCTPTableView from '../../container/HCTPTableView';

function HCTPOutputPage() {
  return (
    <div className="job-details-main">
      <div className="job-details-content">
        <div className="drug-indication-table">
          <HCTPTableView />
        </div>
      </div>
    </div>
  );
}

// HCTPOutputPage.propTypes = {
//   jobId: PropTypes.string.isRequired,
// };

export default HCTPOutputPage;
