import { ResponsiveRadialBar } from '@nivo/radial-bar';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import useAppSelector from '../../../../utils/redux-hook';
import { getSafetyProfileOutputAction } from '../../container/SafetyProfileView/logic';
import Loader from '../../../../common/components/Loader';

function RadialBarGraph({ jobId }) {
  const getSafetyProfileResponse = useAppSelector((state) => state.getSafetyProfileResponse);
  const dispatch = useDispatch();
  useEffect(() => {
    const request = {
      job_id: jobId,
    };
    dispatch(getSafetyProfileOutputAction(queryString.stringify(request)));
  }, []);

  const theme = {
    axis: {
      textColor: '#eee',
      fontSize: '24px',
      tickColor: '#eee',
    },
    // grid: {
    //   stroke: '#888',
    //   strokeWidth: 1
    // },
  };
  const colorScheme = ['#EFD77C', '#F38686', '#58D2A6', '#3B76EF'];
  let ind = -1;
  const getColor = () => {
    ind += 1;
    console.log('bar', ind);
    return colorScheme[ind % colorScheme.length];
  };

  return (
    <div className="safety-profile-graph">
      <div className="tfn-title graph-card-title">Safety Profile of Targets</div>
      <Loader loading={getSafetyProfileResponse.loading}>
        <div style={{ height: '400px', width: '100%' }}>
          <ResponsiveRadialBar
            data={getSafetyProfileResponse.data.data}
            valueFormat=">-.2f"
            cornerRadius={45}
            innerRadius={0.25}
            padding={0.3}
            theme={theme}
            margin={{
              top: 0, right: 0, bottom: 0, left: 0,
            }}
            colors={getColor}
            enableTracks={false}
            enableRadialGrid={false}
            enableCircularGrid={false}
            radialAxisStart={{
              tickSize: 0,
              tickPadding: 10,
              tickRotation: 0,
              // textStyle: {
              //   fontSize: 34,
              //   fontFamily: 'Arial',
              //   fill: '#333', // Adjust font color as needed
              // },
            }}
            circularAxisOuter={null}
            isInteractive={false}
            legends={[]}
          />
        </div>
      </Loader>
    </div>
  );
}

RadialBarGraph.propTypes = {
  jobId: PropTypes.string.isRequired,
};

export default RadialBarGraph;
