import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { appPermission, terminalsPath } from './data';
import { checkPermission } from '../../../utils';
import Mixpanel from '../../../utils/mixpanel';
import SvgLoader from '../../../common/components/SvgLoader';

function Card({
  terminal, description, type, status, setOpen, isBack, getModule,
}) {
  const navigate = useNavigate();

  const check = ((appName) => checkPermission(appName) || true);

  if ((type === 'locked' && status === 'Coming Soon') || (type === 'locked' && check(terminal))) {
    return null;
  }

  if ((type === 'unlocked' && status === 'Coming Soon') || (type === 'unlocked' && !check(terminal))) {
    return null;
  }

  if (type === 'comming soon' && status !== 'Coming Soon') {
    return null;
  }

  const handleRequestClick = () => {
    setOpen();
    getModule(appPermission[terminal]);
  };

  const handlClick = () => {
    Mixpanel.track('Module Visited', {
      action: 'module card on home page clicked',
      module: String(terminal.split('_')[1]).toUpperCase(),
      status,
    });
    if (type === 'unlocked') {
      navigate(terminalsPath[terminal]);
    }
  };

  const renderComingSoon = () => (
    <div className="cs-container">
      <div className="cs-header">Coming Soon!</div>
      <div className="cs-sub-header">{`Our team is working on ${appPermission[terminal]} module for innovative features. We can't wait to unveil its beauty, and we'll update you as soon as it's ready. Stay tuned for something extraordinary!`}</div>
    </div>
  );

  const renderRequestAcces = () => (
    <div className="ra-container">
      <div className="ra-texts">
        <div className="ra-header">{`No ${appPermission[terminal]} access yet? Click 'Request Access' for exciting possibilities!`}</div>
      </div>
      <button onClick={handleRequestClick} className="ra-btn" type="button">
        Request access
        <SvgLoader className="ra-arrow" width={40} height={40} svgName="right-arrow" />
      </button>
    </div>
  );

  const displayContent = (data) => data.map((item) => (
    <div>
      <div style={{ display: 'flex', flex: 'row' }}>
        <FontAwesomeIcon icon={faCheck} style={{ margin: '5px' }} />
        <div
          className="list-text"
          style={{
            fontSize: '16px',
            marginLeft: '5px',
            fontWeight: '400',
          }}
        >
          {item}
        </div>
      </div>
    </div>
  ));

  const renderCard = () => (
    <div aria-hidden onClick={handlClick}>
      <div className="app-image">
        <div className="card-icon">
          <SvgLoader width={40} height={40} svgName={(type === 'locked' && 'locked-icon') || (type === 'Coming Soon' && 'in-progress-icon')} />
        </div>
      </div>
      <div className="app-name">{appPermission[terminal]}</div>
      <div className="app-detail">{(terminal === 'metad3_utilities' || terminal === 'metad3_ae') ? '' : displayContent(JSON.parse(description))}</div>
    </div>
  );
  return (
    <>
      {!isBack && type === 'unlocked' && (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            transition={{ duration: 0.2 }}
            whileHover={{
              scale: type === 'unlocked' ? 1.05 : 1,
              opacity: 1,
              rotateY: type === 'unlocked' ? 0 : 180,
              transition: {
                duration: 0.2,
              },
            }}
          >
            <div className="terminal-card">{renderCard()}</div>
          </motion.div>
        </AnimatePresence>
      )}
      {type !== 'unlocked' && (
        <div aria-hidden className="terminal-card">
          {isBack && type === 'locked' ? renderRequestAcces() : type === 'locked' && renderCard()}
          {isBack && type === 'Coming Soon' ? renderComingSoon() : type === 'Coming Soon' && renderCard()}
        </div>
      )}
    </>
  );
}

Card.propTypes = {
  terminal: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  setOpen: PropTypes.func.isRequired,
  isBack: PropTypes.bool.isRequired,
  getModule: PropTypes.func.isRequired,
};

export default Card;
