import { Button } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

function PrioritizedSequenceLoader({ setPageIndex }) {
  return (
    <>
      <div className="prioritized-sequence-header">Prioritising The Sequence</div>
      <div className="prioritized-sequence-loader-container">
        <div className="prioritized-sequence-loader-body-1">
          <span className="prioritized-sequence-loader-text-upper">498,867</span>
          <span className="prioritized-sequence-loader-text-lower">sequences generated</span>
        </div>
        <div className="prioritized-sequence-loader-body-2">
          <span className="prioritized-sequence-loader-text-upper">498,867</span>
          <span className="prioritized-sequence-loader-text-lower">sequences generated</span>
        </div>
        <div className="prioritized-sequence-loader-body-3">
          <span className="prioritized-sequence-loader-text-upper">498,867</span>
          <span className="prioritized-sequence-loader-text-lower">sequences generated</span>
        </div>
        <div className="prioritized-sequence-loader-body-4">
          <span className="prioritized-sequence-loader-text-upper">498,867</span>
          <span className="prioritized-sequence-loader-text-lower">sequences generated</span>
        </div>
        <div className="prioritized-sequence-loader-body-5">
          <span className="prioritized-sequence-loader-text-upper">498,867</span>
          <span className="prioritized-sequence-loader-text-lower">sequences generated</span>
        </div>
      </div>
      <div className="proceed-btn">
        <Button onClick={() => setPageIndex(2)}>View Prioritized Sequences</Button>
      </div>
    </>
  );
}

PrioritizedSequenceLoader.propTypes = {
  setPageIndex: PropTypes.func.isRequired,
};

export default PrioritizedSequenceLoader;
