import React, { useEffect, useState } from 'react';
import { Radio, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

function RawDataTableView({ selectedData, setSelectedData }) {
  const aeVerifySequencesResponse = useSelector((state) => state.aeVerifySequencesResponse);
  const [data, setData] = useState([]);
  const [sorting, setSorting] = useState('desc');

  useEffect(() => {
    if (aeVerifySequencesResponse.flag && !aeVerifySequencesResponse.loading) {
      const arr = aeVerifySequencesResponse?.data;
      // arr.sort((a, b) => b.Norm_value - a.Norm_value);
      setData(arr);
    }
  }, [aeVerifySequencesResponse]);

  const tableRow = (item) => (
    <div className="table-row-main">
      <div className="table-row-left">
        <Radio
          checked={selectedData === item['Nucleotide Seq']}
          value={item['Nucleotide Seq']}
          onClick={() => {
            if (selectedData === item['Nucleotide Seq']) setSelectedData(null);
            else setSelectedData(item['Nucleotide Seq']);
          }}
        />
        <Tooltip title={item['Nucleotide Seq']}><div className="sequ-title">{item['Nucleotide Seq']}</div></Tooltip>
      </div>
      <div className="table-row-right">
        {item.Norm_value}
      </div>
    </div>
  );
  return (
    <div className="raw-data-table-main">
      <div className="raw-table-container">
        <div className="raw-table-headings header-value-row">
          <div className="table-heading-left">
            <div className="title">AA Light Chain Sequence</div>
            <div className="info-icon" />
          </div>
          <div className="table-heading-right">
            <div className="title">Normalise value</div>
            <div className="sort-icons">
              <div
                className={`arrow-up up-pos ${sorting === 'asc' && 'active'}`}
                role="presentation"
                onClick={() => {
                  if (sorting !== 'asc') {
                    setData((prev) => {
                      const arr = [...prev].reverse();
                      // console.log(arr);
                      return arr;
                    });
                    setSorting('asc');
                  }
                }}
              />
              <div
                className={`arrow-down down-pos ${sorting === 'desc' && 'active'}`}
                role="presentation"
                onClick={() => {
                  if (sorting !== 'desc') {
                    setData((prev) => {
                      const arr = [...prev].reverse();
                      // console.log(arr);
                      return arr;
                    });
                    setSorting('desc');
                  }
                }}
              />
            </div>
          </div>
        </div>
        {console.log(data)}
        {data.map((item) => (
          <div
            className={`table-row ${selectedData === item['Nucleotide Seq'] && 'active'}`}
            style={{ 'background-color': item.color }}
            key={crypto.randomUUID()}
            onClick={() => {
              if (selectedData === item['Nucleotide Seq']) setSelectedData(null);
              else setSelectedData(item['Nucleotide Seq']);
            }}
            role="presentation"
          >
            {tableRow(item)}
          </div>
        ))}
        <div />
      </div>
    </div>
  );
}

RawDataTableView.propTypes = {
  selectedData: PropTypes.string.isRequired,
  setSelectedData: PropTypes.func.isRequired,
};

export default RawDataTableView;
