import React from 'react';
import PropTypes from 'prop-types';
import TargetFeaturesNetworkView from '../../container/TargetFeaturesNetworkView';
import TargetVisibilityView from '../../container/TargetVisibilityView';
import DrugDevelopmentFeasilibilityView from '../../container/DrugDevelopmentFeasilibilityView';
import TopOrganAffectedView from '../../container/TopOrganAffectedView';
import RadialBarGraph from '../../components/RadialBarGraph';
// import PhaseDistributionView from '../../container/PhaseDistributionView';

function TIGraphicalView({ jobId }) {
  return (
    <div className="ti-land-graph-view">
      <div className="ti-land-graph-view-content">
        <div className="ti-land-graph-view-content-graph ti-single-view-graph">
          <div className="ti-land-graph-view-content-graph-sec">
            <TargetFeaturesNetworkView jobId={jobId} />
          </div>
        </div>
        <div className="ti-land-graph-view-content-graph ti-combine-view-graph">
          <div className="ti-combine-view-graph-sec">
            <div className="ti-combine-view-graph-sec-three">
              <div className="ti-combine-view-graph-sec-three-top">
                <div className="ti-land-graph-view-content-graph-sec">
                  <TargetVisibilityView jobId={jobId} />
                </div>
                <div className="ti-land-graph-view-content-graph-sec">
                  {/* <SafetyProfileView jobId={jobId} /> */}
                  <RadialBarGraph jobId={jobId} />
                </div>
              </div>
              <div className="ti-combine-view-graph-sec-three-bottom">
                <div className="ti-land-graph-view-content-graph-sec">
                  <DrugDevelopmentFeasilibilityView jobId={jobId} />
                </div>
              </div>
            </div>
          </div>
          <div className="ti-combine-view-graph-sec">
            <div className="ti-land-graph-view-content-graph-sec">
              <TopOrganAffectedView jobId={jobId} />
            </div>
          </div>
        </div>
        {/* <div className="ti-land-graph-view-content-graph ti-single-view-graph">
          <div className="ti-land-graph-view-content-graph-sec">
            <PhaseDistributionView jobId={jobId} diseaseName="colorectal neoplasm" />
          </div>
        </div> */}
      </div>
    </div>
  );
}

TIGraphicalView.propTypes = {
  jobId: PropTypes.string.isRequired,
};
export default TIGraphicalView;
