import Rcookie from 'react-cookies';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js';

export const PATHS = [
  ...Object.keys(process.env.APPS).map((item) => process.env.APPS[item].mainRoute),
];

export const setCookie = (value, key) => {
  const { domain } = process.env;
  const expires = new Date();
  expires.setDate(new Date().getDate() + 1);
  Rcookie.save(`${process.env.app}_${key}_${process.env.type}`, value, { path: '/', domain, expires });
};

export const removeCookies = (flag) => {
  const cookies = document.cookie.split(';');
  const { domain } = process.env;
  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    PATHS.forEach((item) => {
      if (flag) {
        if (name.includes(`${process.env.app}_`)) {
          document.cookie = `${name} =;expires=Thu, 01 Jan 1970 00:00:00 GMT ; domain=${domain}; path=${item}`;
        }
      } else {
        document.cookie = `${name} =;expires=Thu, 01 Jan 1970 00:00:00 GMT ; domain=${domain}; path=${item}`;
      }
      return 0;
    });
  }
};

export const generateHashUrl = (value, navigate) => {
  navigate(`/search?${queryString.stringify(value)}`, { replace: true });
  navigate(0);
};

export const getCookie = (key, app) => Rcookie.load(`${app || process.env.app}_${key}_${process.env.type}`);

export function ucFirst(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
}

export default function apiCall(url, method, body = {}, authReq = true, customHeaders = {}, contentType = 'application/json') {
  let obj = {};
  if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
    obj = {
      method,
      url,
      body,
    };
  } else {
    obj = {
      method,
      url: `${url}${body && Object.keys(body).length ? `?${queryString.stringify(body)}` : ''}`,
    };
  }
  const headers = {
    Authorization: (Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : ''),
    'Content-Type': contentType,
    'Access-Control-Allow-Origin': `${process.env.applicationUrl}`,
    ...customHeaders,
  };
  Object.entries(headers).forEach((en) => {
    if (en[1] === undefined || (!authReq && en[0] === 'Authorization')) {
      delete headers[en[0]];
    }
  });
  return ({
    ...obj,
    headers,
  });
}

export const checkPermission = (value) => {
  if (getCookie('permissions')) {
    return getCookie('permissions').indexOf(value) > -1;
  }
  return false;
};

export const makeFirstLetterCapital = (value) => {
  let remadeValue = '';
  if (!value) {
    return null;
  }
  const valueArr = value.toLowerCase().split(' ');
  valueArr.forEach((word) => {
    remadeValue += `${word.slice(0, 1).toUpperCase()}${word.slice(1)} `;
  });
  return remadeValue.trim();
};

export const modifyDataForDropdown = (data, labelKey, ValueKey) => {
  const options = data.map((item) => ({
    ...item,
    label: item[labelKey],
    value: item[ValueKey],
  }));
  return options;
};

let timer = null;
export const debounceCall = (callback, debounceTime) => {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    callback();
  }, debounceTime);
};

export const getAllPermission = () => getCookie('permissions');
export const getTempSessionId = () => {
  if (getCookie('tempSesion')) {
    return getCookie('tempSesion');
  }

  const randomString = uuidv4();
  const hash = CryptoJS.SHA256(randomString).toString(CryptoJS.enc.Hex);
  setCookie(hash, 'tempSesion');
  return hash;
};

export function apiCallwithoutCT(url, method, body = {}) {
  let obj = {};
  if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
    obj = {
      method,
      url,
      body,
    };
  } else {
    obj = {
      method,
      url: `${url}${body && Object.keys(body).length ? `?${queryString.stringify(body)}` : ''}`,
    };
  }

  return ({
    ...obj,
    headers: {
      Authorization: (Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : ''),
      'Access-Control-Allow-Origin': `${process.env.applicationUrl}`,
    },
  });
}
