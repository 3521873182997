import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Header from '../../../../common/components/Header';
import SelectService from '../../container/SelectService';
import InputBox from '../../container/InputBox';
import useAppSelector from '../../../../utils/redux-hook';
import { terminalsPath } from '../../../../app/components/Card/data';
import { sendNotification } from '../../../../common/components/Notification/logic';
import Loader from '../../../../common/components/Loader';
import InputIndication from '../../container/InputIndication';
import InputStudyProtocols from '../../container/InputStudyProtocols';
import SaveProjectModal from '../../container/SaveProjectModal';
import Mixpanel from '../../../../utils/mixpanel';

function CTPInputPage() {
  const [inputs, setInputs] = useState({
    id: '',
    dataset: '',
    card: '',
    indication: '',
    drugIndication: '',
    pairsFile: null,
    isUploadFile: false,
  });
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const service = searchParams.get('service');
  const nctId = searchParams.get('nctId');
  const dataset = searchParams.get('dataset');
  const indication = searchParams.get('indication');
  const statusGenerateInsights = useAppSelector((state) => state.statusGenerateInsights);
  const services = ['PREDICT_CLINICAL_TRIAL_SUCCESS', 'CHECK_TRIAL_FEASIBILITY', 'HCTP'];
  const [isShowModal, setIsShowModal] = useState(false);

  const processInput = (text) => text?.split('\n').filter((line) => line);

  useEffect(() => {
    if (statusGenerateInsights.flag) {
      dispatch(
        sendNotification({
          type: 'REQUEST_SUCCESS',
          notificationType: 'success',
          message: statusGenerateInsights.data,
          showNotification: true,
        }),
      );
    }
  }, [JSON.stringify(statusGenerateInsights)]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setInputs({
      id: nctId === 'null' ? '' : nctId,
      dataset: dataset === 'null' ? '' : dataset,
      card: service === 'null' ? '' : service,
      indication: indication === 'null' ? '' : indication,
      drugIndication: '',
      pairsFile: null,
      isUploadFile: false,
    });
  }, []);

  const handleClick = () => {
    if (inputs.card === 'CHECK_TRIAL_FEASIBILITY') {
      localStorage.removeItem('inputIndications');
      localStorage.setItem('inputIndications', JSON.stringify(processInput(inputs.indication)));
      navigate(`${terminalsPath.metad3_ctp_output}?service=${inputs.card}`);
      Mixpanel.track('Meta-CTP CFS Output Generated', {
        action: 'Meta-CTP Generate Insight output button clicked',
        service: 'Check Trial Feasibility',
        module: 'Meta-CTP',
        indications: localStorage.getItem('inputIndications'),
      });
    } else {
      setIsShowModal(true);
    }
  };

  const getInputs = (input) => {
    setInputs((prev) => ({
      ...prev,
      ...input,
    }));
  };
  const checkBtnDissabledForAccess = () => {
    if (inputs.card === '') return true;

    if (inputs.card === 'PREDICT_CLINICAL_TRIAL_SUCCESS') {
      return inputs?.id?.trim() === '' || inputs?.dataset === '';
    }
    if (inputs.card === 'CHECK_TRIAL_FEASIBILITY') {
      return inputs?.indication?.trim() === '';
    }
    if (inputs.card === 'HCTP') {
      console.log('Disabled --->', inputs?.pairsFile === null);
      return inputs?.drugIndication?.trim() === '' && inputs?.pairsFile === null;
    }
    return false;
  };

  useEffect(() => {
    setIsDisabled(checkBtnDissabledForAccess());
  }, [inputs]);

  const inputPanel = () => {
    if (inputs.card === services[0]) return <InputBox inputHandler={getInputs} allInputs={inputs} isDisabled={inputs.card === null} />;
    if (inputs.card === services[1]) return <InputIndication inputHandler={getInputs} allInputs={inputs} isDisabled={inputs.card === null} />;
    if (inputs.card === services[2]) return <InputStudyProtocols inputHandler={getInputs} allInputs={inputs} isDisabled={inputs.card === null} />;
    return <InputBox inputHandler={getInputs} allInputs={inputs} isDisabled={inputs.card === ''} />;
  };

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home ctp-header" logo="meta-ctp" width={550} isWorkspace modulePath={terminalsPath.metad3_ctp_workspace} />
      <Loader loading={false} height="800px" width="100%">
        <div className="ct-home-page">
          <div className="main-heading">
            <div className="main-title">Clinical trial success prediction & Protocol optimisation</div>
            <div className="main-description">Using our AI model you can predict clinical trial success, check feasibility, and hypothesize clinical trial prediction with just three simple steps.</div>
          </div>
          <SelectService inputHandler={getInputs} service={service} services={services} />

          {inputPanel()}
          <div className="ct-home-page-footer">
            <button onClick={() => handleClick()} className="generate-in-btn" type="button" disabled={!inputs.card || isDisabled}>
              GENERATE INSIGHTS
            </button>
          </div>
        </div>
        <SaveProjectModal
          open={isShowModal}
          setOpen={setIsShowModal}
          requestBody={inputs}
        />
      </Loader>
    </div>
  );
}

export default CTPInputPage;
