/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toPng } from 'html-to-image';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import PathEnrichmentGraph from '../../TopIndicationGraphs/PathwayEnrichment';
import EnrichmentGraph from '../../TopIndicationGraphs/EnrichmentGraph';
import AssociatedProteinDistributionGraph from '../../TopIndicationGraphs/AssociatedProteinDistributionGraph';
import PPINetwotkGraph from '../../TopIndicationGraphs/PPINetworkGraph';
import LiteratureMiningGraph from '../../TopIndicationGraphs/LiteratureMiningGraph';
import IndicationsLandscapeView from '../../TopIndicationGraphs/IndicationsLandscapeView';
import PPINetwotkGraph2 from '../../TopIndicationGraphs/PPINetworkGraph2';
import useAppSelector from '../../../../../utils/redux-hook';

function LandscapeView({ jobId }) {
  const ref = useRef(null);
  const getIndicationOutputResponse = useAppSelector((state) => state.getIndicationOutputResponse);
  const [isRareIndication, setIsRareIndication] = useState(false);

  useEffect(() => {
    if (getIndicationOutputResponse) {
      setIsRareIndication(getIndicationOutputResponse?.data?.data?.jobData?.user_input.rare_indication_flag);
      console.log('IEP response', getIndicationOutputResponse?.data?.data?.jobData?.user_input.rare_indication_flag, isRareIndication);
    }
  });

  const onButtonClick = useCallback(() => {
    if (ref.current === null) {
      return;
    }

    toPng(ref.current, { cacheBust: true, quality: 1 })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'my-image-name.png';
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref]);
  return (
    <div className="landscapeview-graphs">
      <div className="ctp-content-lower card-content-ctp card-content-ctp-graph">
        <AssociatedProteinDistributionGraph jobId={jobId} />
        {/* <Button onClick={onButtonClick}>Download</Button> */}
      </div>
      {/* <div className="ctp-content-lower card-content-ctp card-content-ctp-graph">
        <PPINetwotkGraph jobId={jobId} />
      </div> */}
      <div className="ctp-content-lower card-content-ctp card-content-ctp-graph">
        <PPINetwotkGraph2 jobId={jobId} />
      </div>
      {!isRareIndication && <div className="ctp-content-lower card-content-ctp card-content-ctp-graph">
        <PathEnrichmentGraph jobId={jobId} />
      </div>}
      {!isRareIndication && <div className="ctp-content-lower card-content-ctp card-content-ctp-graph">
        <EnrichmentGraph jobId={jobId} />
      </div>}
      <div className="ctp-content-lower card-content-ctp card-content-ctp-graph">
        <LiteratureMiningGraph jobId={jobId} />
      </div>
      <div className="ctp-content-lower card-content-ctp card-content-ctp-graph">
        <IndicationsLandscapeView jobId={jobId} />
      </div>
    </div>
  );
}

LandscapeView.propTypes = {
  jobId: PropTypes.string.isRequired,
};
export default LandscapeView;
