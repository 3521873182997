/* eslint-disable */
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import Header from '../../../../common/components/Header';
import { iepCheckStatusAction } from '../IEPInputPage/logic';
import TopIndicationsPage from '../TopIndicationsPage';
import { terminalsPath } from '../../../../app/components/Card/data';

function IEPOutputPage() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get('jobId');

  useEffect(() => {
    dispatch(iepCheckStatusAction());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home iep-header" logo="iep-logo" isWorkspace modulePath={terminalsPath.metad3_iep_workspace} />
      <TopIndicationsPage jobId={jobId} />
    </div>
  );
}

export default IEPOutputPage;
